import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Avatar } from "@material-ui/core";
import lod_ from "lodash";
import Alert from "@material-ui/lab/Alert";
import { ANSWER_TYPE_BY_CHANNEL, handleChannelsSelection } from "../AnswersUtils";
import SweetAlert from "react-bootstrap-sweetalert";
import { uniqueId } from "../../../utilities/utilities";
import GetChannelImage from "../../../helpers/GetChannelImage";
import i18n from "../../../components/i18n/i18n";
import C from "../../../constants/admin";
import {
	createAlternative,
	s3DeleteFile,
	setActivated,
	setBuilIn,
	updateAlternative
} from "redux/reducers/answersReducers";
import { useDispatch, useSelector } from "react-redux";
import AnswerCode from "./Steps/AnswerCode";
import AlternativeList from "./Steps/AlternativeList";
import ChannelsList from "./Steps/ChannelsList";
import TypesList from "./Steps/TypesList";
import { GetTypeIcon } from "../AnswersUtils";
import Content from "./Steps/Content";

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%"
	},
	button: {
		marginRight: theme.spacing(1)
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1)
	}
}));

const getAnswerByCode = (code, answers) => {
	return answers.find(element => element.codeReponse === code);
};

export default function HorizontalLinearStepper({
	editAnswer,
	userLanguage,
	getContentByUserLanguage,
	langues
}) {
	const dispatch = useDispatch();
	const assistantID = useSelector(state => state.selectedAssistantID);
	const channelsPossibles = useSelector(state =>
		state.assistantconfig?.channels?.filter(channel => channel.type !== "FO")
	);
	const { alternativeTags } = useSelector(state => state.tags);
	const { tags } = useSelector(state => state.assistantconfig);
	const answers = useSelector(state => state.answers.data);
	const { activated, builtIn } = useSelector(state => state.answers);
	//	const { attachments } = useSelector(state => state.answers);
	const classes = useStyles();
	const [activeStep, setActiveStep] = useState(0);
	const [skipped, setSkipped] = useState(new Set());
	const steps = getSteps();
	const [answerCode, setAnswerCode] = useState();
	const [answer, setAnswer] = useState();
	const [error, setError] = useState([]);
	const [showAlert, setShowAlert] = useState({
		open: false,
		success: true
	});
	const [description, setDescription] = useState(editAnswer?.alternative?.description || "");
	const [type, setType] = useState(editAnswer?.alternative?.type || "");
	/**
	 *
	 */
	const [content, setContent] = useState(editAnswer?.alternative?.content || []);
	const [channels, setChannels] = useState(editAnswer?.alternative?.channels || {});
	const [typesPossibles, setTypesPossibles] = useState([]);
	const [uid, setUid] = useState(editAnswer?.alternative?.uid || "");
	const [languages, setLanguages] = useState(editAnswer?.alternative?.languages || []);

	/**
	 *
	 * @returns
	 */
	function getSteps() {
		return [
			i18n.t("ANSWERS.answerCode"),
			i18n.t("ANSWERS.alternative"),
			i18n.t("ANSWERS.channels"),
			i18n.t("ANSWERS.type"),
			i18n.t("ANSWERS.content")
		];
	}

	/**
	 *
	 */
	useEffect(() => {
		return () => {
			//check if has new files unsaved, if so delete them
			let oldFiles = editAnswer?.alternative?.content?.map(c => c.content.file);
			let newFiles = content.map(c => c.content.file);
			newFiles?.map(async file => {
				if (!oldFiles?.includes(file)) {
					dispatch(s3DeleteFile(answerCode, uid, assistantID, file.path, file.name));
				}
			});
		};
	}, []);

	/**
	 *
	 */
	useEffect(() => {
		if (uid) {
			const alt = lod_.find(answer?.alternatives, { uid: uid });
			if (alt) {
				setDescription(alt.description);
				setType(alt.type);
				setChannels(alt.channels);
				setContent(alt.content);
			}
		}
	}, [uid]);

	/**
	 * if FormStepper opened by a click on an EditButton (shortcut), redirect to the right step
	 */
	useEffect(() => {
		let step = 0;
		//Answer  item selected
		if (editAnswer.codeReponse) {
			step = 1;
			setAnswer(editAnswer);
			setAnswerCode(editAnswer.codeReponse);
		}
		//Alternatve selected
		else if (editAnswer.alternative && editAnswer.alternative !== "") {
			setAnswerCode(editAnswer.answer);
			setDescription(editAnswer.alternative.description);
			setType(editAnswer.alternative.type);
			setChannels(editAnswer.alternative.channels);
			setContent(editAnswer.alternative.content);
			dispatch(setActivated(editAnswer.alternative.activated));
			dispatch(setBuilIn(editAnswer.alternative.built_in));
			step = 4;
		}
		setActiveStep(step);
	}, [editAnswer]);

	/**
	 * After step 0, get answer by code entered
	 */
	useEffect(() => {
		let foundAnswer = getAnswerByCode(answerCode, [...answers]);
		if (foundAnswer) {
			foundAnswer = JSON.parse(JSON.stringify(foundAnswer));
			setAnswer(foundAnswer);
		} else setAnswer();
	}, [answerCode, answers]);

	/**
	 * get possible types by alternatives's channels
	 */
	useEffect(() => {
		if (channels) {
			let types = [];
			Object.keys(channels).map(channel => {
				const typesByChannel = ANSWER_TYPE_BY_CHANNEL[channel]
					? ANSWER_TYPE_BY_CHANNEL[channel]
					: [];
				return (types = [...types, ...typesByChannel]);
			});
			types = lod_.uniq(types);
			setTypesPossibles(types);
		}
	}, [channels]);

	const isStepSkipped = step => {
		return skipped.has(step);
	};

	const isStepOptional = step => {
		return null;
	};

	const callback = success => {
		setShowAlert({ open: true, success: success });
	};

	/**
	 *
	 * @param {*} step
	 * @returns the stepper content
	 */
	const getStepContent = step => {
		switch (step) {
			case 0: //answer code
				return <AnswerCode answers={answers} setAnswerCode={setAnswerCode} />;
			case 1: //alternatives list
				if (answer)
					return (
						<AlternativeList
							answer={answer}
							values={answer.alternatives}
							setUid={setUid}
							//setAlternative={setAlternative}
							answerCode={answerCode}
							uniqueId={uniqueId}
							userLanguage={userLanguage}
							getContentByUserLanguage={getContentByUserLanguage}
						/>
					);
				else return <></>;
			case 2: //channels
				return <ChannelsList channels={channels} setChannels={setChannels} />;
			case 3: //Answer type
				return (
					<TypesList
						typesPossibles={typesPossibles}
						type={type}
						setType={setType}
						content={content}
						setContent={setContent}
					/>
				);
			case 4: //Content
				return (
					<Content
						content={content}
						setContent={setContent}
						uid={uid}
						type={type}
						description={description}
						setDescription={setDescription}
						error={error}
						editAnswer={editAnswer}
						langues={langues}
						answerCode={answerCode}
						assistantID={assistantID}
						channels={channels}
					/>
				);
			default:
				return "Unknown step";
		}
	};

	const handleErrors = () => {
		let errorStep = [];
		if ((activeStep === 0 && !answerCode) || C.NOT_DISPLAYABLE_ANSWER_ITEM.includes(answerCode)) {
			errorStep.push(i18n.t("ANSWERS.errorNoCodeReponse"));
		}
		if (activeStep === 1 && !uid) {
			errorStep.push(i18n.t("ANSWERS.errorNoAlternative"));
		}
		if (activeStep === 2 && Object.keys(channels).length === 0) {
			errorStep.push(i18n.t("ANSWERS.errorNoChannel"));
		}
		if (activeStep === 3 && !type) {
			errorStep.push(i18n.t("ANSWERS.errorNoType"));
		}
		if (activeStep === 4) {
			if (content.length === 0) errorStep.push(i18n.t("ANSWERS.errorNoVariant"));
			else {
				content.forEach(content => {
					if (type === "menutext") {
						if (!content.content.choice) {
							errorStep.push(i18n.t("ANSWERS.choices"));
						}
						if (lod_.isNil(content.content.title) || content.content.title === "") {
							errorStep.push(i18n.t("ANSWERS.title"));
						}
					}
					if (type === "location") {
						if (!content.content.address) {
							errorStep.push(i18n.t("ANSWERS.noAddress"));
						}
						if (!content.content.addressName) {
							errorStep.push(i18n.t("ANSWERS.noAddressName"));
						}
						if (!content.content.longitude) {
							errorStep.push(i18n.t("ANSWERS.noLongitude"));
						}
						if (!content.content.latitude) {
							errorStep.push(i18n.t("ANSWERS.noLatitude"));
						}
					}
					if (type === "document") {
						if (!content.content.url) {
							errorStep.push(i18n.t("ANSWERS.noUrl"));
						}
						if (!content.content.caption) {
							errorStep.push(i18n.t("ANSWERS.noCaption"));
						}
						if (!content.content.filename) {
							errorStep.push(i18n.t("ANSWERS.noFilename"));
						}
						/*if (!content.content.file) {
              errorStep.push(i18n.t("ANSWERS.noFile"));
            }*/
					}
					if (type === "video" || type === "image") {
						if (!content.content.url) {
							errorStep.push(i18n.t("ANSWERS.noUrl"));
						}
						if (!content.content.caption) {
							errorStep.push(i18n.t("ANSWERS.noCaption"));
						}
						/*if (!content.content.file) {
              errorStep.push(i18n.t("ANSWERS.noFile"));
            }*/
					}
				});
			}
		}
		setError(errorStep);
		return errorStep;
	};

	const removeContent = (index, newContent, newLanguages) => {
		if (!newContent) newContent = lod_.cloneDeep(content);
		if (!newLanguages) newLanguages = lod_.cloneDeep(languages);
		newLanguages.splice(languages.indexOf(content.language), 1);
		newContent.splice(index, 1);

		return newContent;
	};
	/**
	 * if alternative contains empty content, remove it
	 */
	const handleEmptyContent = () => {
		let newContent = lod_.cloneDeep(content);
		let newLanguages = lod_.cloneDeep(languages);
		newContent.forEach((content, index) => {
			if (Object.keys(content.content).length === 0) {
				newContent = removeContent(index, newContent, newLanguages);
			} else {
				switch (type) {
					case "menutext": {
						if (content.content.title === "" && content.content.choice.length === 0) {
							newContent = removeContent(index, newContent, newLanguages);
						}
						break;
					}
					case "menuyesno": {
						if (content.content.title === "") {
							newContent = removeContent(index, newContent, newLanguages);
						}
						break;
					}
					case "document": {
						if (
							content.content.url === "" &&
							content.content.caption === "" &&
							content.content.filename === ""
						)
							newContent = removeContent(index, newContent, newLanguages);

						break;
					}
					case "video": {
						if (content.content.url === "" && content.content.caption === "")
							newContent = removeContent(index, newContent, newLanguages);

						break;
					}
					case "image": {
						if (content.content.url === "" && content.content.caption === "")
							newContent = removeContent(index, newContent, newLanguages);

						break;
					}
					case "location": {
						if (
							content.content.longitude === "" &&
							content.content.latitude === "" &&
							content.content.addressName === "" &&
							content.content.address === ""
						)
							newContent = removeContent(index, newContent, newLanguages);

						break;
					}
					default: {
						if (content.content.text === "") {
							newContent = removeContent(index, newContent, newLanguages);
						}
					}
				}
			}
		});
		setLanguages(newLanguages);
		setContent(newContent);
		return newContent;
	};

	/**
	 * On next button click , manage form errors and steps
	 */
	const handleNext = () => {
		const contentCleared = handleEmptyContent();

		let errorStep = handleErrors();

		if (errorStep.length === 0) {
			let newSkipped = skipped;
			if (isStepSkipped(activeStep)) {
				newSkipped = new Set(newSkipped.values());
				newSkipped.delete(activeStep);
			}
			let nextStep;
			let type;

			const typesByChannel = ANSWER_TYPE_BY_CHANNEL[channelsPossibles[0]?.type] || [];
			if (activeStep === 0 && (!answer || answer.alternatives.length === 0)) {
				nextStep = 2; // Skip alternatives list
				if (channelsPossibles.length === 1) {
					// 1 channel possible
					nextStep = 3; // Skip channels list
					//select this channel
					const newChecked = handleChannelsSelection(
						null,
						channelsPossibles,
						channels,
						channelsPossibles[0],
						false
					);
					setChannels(newChecked);
					if (typesByChannel.length === 1) {
						// 1 type possible
						nextStep = 4; //Skip types list
						type = typesByChannel[0];
					}
				}
				const answerCodeFormatted = answerCode
					.trim()
					?.toLowerCase()
					.replace(/[^A-Za-z0-9_-]/gi, "");
				setAnswerCode(answerCodeFormatted);
				if (type) setType(type);
				setUid(`${answerCodeFormatted}_${uniqueId()}`);
			} else if (activeStep === 1 && channelsPossibles.length === 1) {
				nextStep = 2;
				let channelsToSelect = handleChannelsSelection(
					null,
					channelsPossibles,
					channels,
					channelsPossibles[0],
					false
				);
				setChannels(channelsToSelect);
				if (typesByChannel.length === 1) {
					type = typesByChannel[0];
					nextStep = 4;
				}
			} else if (activeStep === 2 && typesPossibles.length === 1) {
				type = typesPossibles[0];
				nextStep = 4;
			}
			if (type) setType(type);
			setActiveStep(prevActiveStep => (nextStep ? nextStep : prevActiveStep + 1));
			setSkipped(newSkipped);
		}

		//HANDLE FINISH
		if (activeStep === steps.length - 1 && errorStep.length === 0) {
			let alternative = {
				activated: activated,
				built_in: builtIn,
				channels: channels,
				content: contentCleared,
				description: description,
				languages: languages,
				type: type,
				uid: uid,
				name: answerCode,
				assistantID: assistantID,
				tags: alternativeTags
			};
			if (alternative.tags.length === 0 && tags && tags.length > 0) {
				setError({ tags: i18n.t("ANSWERS.noTags") });
				setActiveStep(4);
			} else {
				const allLanguageCompleted = contentCleared.length >= langues.length;
				const isNotReviewType = !Object.keys(channels).includes("WS"); // No modification concern the WS channel. That need language completion

				if (isNotReviewType || allLanguageCompleted) {
					//save alternative
					const answerCodeExists = lod_.findIndex(answers, {
						codeReponse: answerCode
					});
					if (answerCodeExists === -1) {
						//create
						dispatch(
							createAlternative(
								{
									...alternative,
									name: answerCode,
									assistantID: assistantID
								},
								() => callback(true),
								() => callback(false)
							)
						);
					} else {
						//update
						let oldAlternative = lod_.find(answers, {
							codeReponse: answerCode
						});
						oldAlternative = lod_.find(oldAlternative.alternatives, {
							uid: uid
						});
						if (oldAlternative) {
							dispatch(
								updateAlternative(
									{ ...alternative },
									() => callback(true),
									() => callback(false)
								)
							);
						} else {
							dispatch(
								createAlternative(
									{ ...alternative },
									() => callback(true),
									() => callback(false)
								)
							);
						}
					}
				} else {
					// This appends only if it's review and all languages are not completed
					let errors = {};
					const languagesContent = Object.values(contentCleared).map(c => c.language);

					const diff = langues.filter(language => !languagesContent.includes(language));
					diff.forEach(langue => {
						errors[langue] = i18n.t("ANSWERS.errorNoVariant");
					});
					setError(errors);
					setActiveStep(4);
				}
			}
		}
	};

	const hideAlert = () => {
		setShowAlert({ open: false, success: true });
	};

	/**
	 * On back button click
	 */
	const handleBack = () => {
		if (activeStep === 2 && !answer) setActiveStep(0);
		else if (activeStep === 4 && typesPossibles.length === 1) {
			if (channelsPossibles.length === 1) setActiveStep(1);
			else setActiveStep(2);
		} else if (activeStep === 3 && channelsPossibles.length === 1) setActiveStep(1);
		else setActiveStep(prevActiveStep => prevActiveStep - 1);
	};

	const handleSkip = () => {
		if (!isStepOptional(activeStep)) {
			// You probably want to guard against something like this,
			// it should never occur unless someone's actively trying to break something.
			throw new Error("You can't skip a step that isn't optional.");
		}

		setActiveStep(prevActiveStep => prevActiveStep + 1);
		setSkipped(prevSkipped => {
			const newSkipped = new Set(prevSkipped.values());
			newSkipped.add(activeStep);
			return newSkipped;
		});
	};

	const handleReset = () => {
		setAnswerCode("");
		setAnswer();
		setActiveStep(0);
	};

	const stepperValues = [
		<Typography variant="caption" key="0" style={{ fontVariantCaps: "small-caps" }}>
			{answerCode}
		</Typography>,
		<Typography variant="caption" key="1">
			{
				//getContentByUserLanguage(content, "FR")
			}
		</Typography>,
		<Typography variant="caption" key="2">
			{" "}
			{Object.keys(channels).map((channelType, index) => {
				if (channels[channelType].length > 0) {
					return channels[channelType].map((channelCode, index) => {
						return (
							<Avatar
								style={{ width: "15px", height: "15px", display: "inline-flex" }}
								className={classes.small}
								alt={channelType}
								src={GetChannelImage(channelCode, channelType)}
								key={index}
							/>
						);
					});
				} else {
					return (
						<Avatar
							style={{ width: "15px", height: "15px", display: "inline-flex" }}
							className={classes.small}
							alt={channelType}
							src={GetChannelImage(channelType)}
							key={index}
						/>
					);
				}
			})}
		</Typography>,

		<span className="iconContainer" key="3" style={{}}>
			{GetTypeIcon(type)}
		</span>
	];

	return (
		<div className={classes.root}>
			<Stepper activeStep={activeStep} style={{ marginBottom: "5%" }}>
				{steps.map((label, index) => {
					const stepProps = {};
					const labelProps = {};
					if (isStepOptional(index)) {
						labelProps.optional = <Typography variant="caption">Optional</Typography>;
					}
					if (error.length > 0) {
						labelProps.optional = (
							<Typography variant="caption" color="error">
								{i18n.t("ANSWERS.error")}
							</Typography>
						);
					}
					if (activeStep > index) labelProps.optional = stepperValues[index];

					if (isStepSkipped(index)) {
						stepProps.completed = false;
					}
					return (
						<Step key={label} {...stepProps}>
							<StepLabel {...labelProps}>{label}</StepLabel>
						</Step>
					);
				})}
			</Stepper>
			<div>
				{activeStep === steps.length ? (
					<div>
						<Typography className={classes.instructions}>{i18n.t("ANSWERS.finished")}</Typography>
						<Button onClick={handleReset} className={classes.button}>
							{i18n.t("ANSWERS.reset")}
						</Button>
					</div>
				) : (
					<div>
						{error.length > 0 && (
							<Alert severity="error">
								<ul>
									{error.map((e, index) => (
										<li key={index}>{e}</li>
									))}
								</ul>
							</Alert>
						)}
						{getStepContent(activeStep)}
						<div
							style={{
								marginTop: "5%",
								textAlign: "right",
								bottom: "0%",
								right: "0"
							}}
						>
							<Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
								{i18n.t("ANSWERS.back")}
							</Button>
							{isStepOptional(activeStep) && (
								<Button
									variant="contained"
									color="primary"
									onClick={handleSkip}
									className={classes.button}
								>
									{i18n.t("ANSWERS.skip")}
								</Button>
							)}

							<Button
								variant="contained"
								color="primary"
								onClick={handleNext}
								className={classes.button}
							>
								{activeStep === steps.length - 1
									? i18n.t("ANSWERS.finish")
									: i18n.t("ANSWERS.next")}
							</Button>
						</div>
					</div>
				)}
			</div>
			<SweetAlert
				success={showAlert.success}
				error={!showAlert.success}
				title={showAlert.success ? i18n.t("alert.save") : i18n.t("alert.cannot")}
				timeout={2000}
				show={showAlert.open}
				onConfirm={hideAlert}
				onCancel={hideAlert}
				allowEscape={true}
				customButtons={
					<Button className="success" onClick={hideAlert}>
						{i18n.t("alert.confirm")}
					</Button>
				}
			/>
		</div>
	);
}
