import i18n from "../../../../components/i18n/i18n";
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ListItem, ListItemIcon, ListItemText, List, Checkbox, TextField } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	root: {
		//width: '100%',
		//maxWidth: 360,
		width: "70%",
		marginLeft: "15%",
		backgroundColor: theme.palette.background.paper
	}
}));

/**
 * Second step of the form
 */
const AlternativeList = ({
	values,
	answer,
	setUid,
	answerCode,
	uniqueId,
	userLanguage,
	getContentByUserLanguage
}) => {
	const classes = useStyles();
	const [checked, setChecked] = React.useState("");
	const [description, setDescription] = React.useState("");

	useEffect(() => {
		if (checked !== "new") setUid(values[checked]?.uid);
		else setUid(`${answerCode}_${uniqueId()}`);
	}, [checked, values, setUid, answerCode, uniqueId]);

	useEffect(() => {
		if (checked === "new" && description !== "") setUid(`${answerCode}_${uniqueId()}`);
	}, [description, values, checked, answerCode, uniqueId, setUid]);

	const handleToggle = value => () => {
		setChecked(value);
	};

	return (
		<>
			<h1>{i18n.t("ANSWERS.alternativeChoice")}</h1>
			{answer && (
				<List className={classes.root}>
					{values.map((value, index) => {
						const labelId = `checkbox-list-label-${index}`;
						return (
							<ListItem
								style={{ borderBottom: "1px solid #ececec" }}
								key={index}
								role={undefined}
								dense
								button
								onClick={handleToggle(index)}
							>
								<ListItemIcon>
									<Checkbox
										edge="start"
										checked={checked === index}
										tabIndex={-1}
										disableRipple
										inputProps={{ "aria-labelledby": labelId }}
									/>
								</ListItemIcon>
								<ListItemText
									id={labelId}
									primary={getContentByUserLanguage(value, userLanguage)}
									style={{ fontWeight: "600" }}
								/>
							</ListItem>
						);
					})}
					<ListItem key={"new"} role={undefined} dense button onClick={handleToggle("new")}>
						<ListItemIcon>
							<Checkbox
								edge="start"
								checked={checked === "new"}
								tabIndex={-1}
								disableRipple
								inputProps={{ "aria-labelledby": "new" }}
							/>
						</ListItemIcon>
						{checked === "new" ? (
							<TextField
								value={description}
								onChange={e => setDescription(e.target.value)}
								placeholder={i18n.t("ANSWERS.description")}
							/>
						) : (
							<ListItemText id={"new"} primary={i18n.t("ANSWERS.newAlternative")} />
						)}
					</ListItem>
				</List>
			)}
		</>
	);
};

export default AlternativeList;
