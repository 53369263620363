import { getUploadS3SignedUrlImage, uploadS3FromSignedUrl } from "helpers/s3";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addAttachment } from "redux/reducers/answersReducers";
import { fetchUploadImageToS3 } from "redux/reducers/s3Reducers";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import { fr } from "suneditor/src/lang";
import plugins from "suneditor/src/plugins";
import addAttachments from "./plugins/AddAttachment";

/**
 *
 * @param {*} param0
 * @returns
 */
export const SunEditorComponent = ({
	handleChange,
	value,
	placeholder,
	hasAttachments,
	fileObject,
	channelCode
}) => {
	const suneditor = useRef();
	const fileInputRef = useRef();
	const dispatch = useDispatch();
	const assistantID = useSelector(state => state.selectedAssistantID);

	let buttonList = [
		["bold", "underline", "italic", "strike"],
		["list", "align"],
		["link", "image"],
		["-right", "fullScreen"]
	];
	let options = {
		buttonList: buttonList,
		lang: fr,
		showPathLabel: false
	};

	/**
	 *
	 */
	const handleAddAttachment = () => {
		fileInputRef.current.click();
	};

	const thePlugins = {
		...plugins,
		attachmentPlugin: addAttachments({ handleAddAttachment })
	};

	if (hasAttachments) {
		buttonList[2].push("add_attachment");
		options = { ...options, plugins: thePlugins };
	}

	const getSunEditorInstance = sunEditor => {
		suneditor.current = sunEditor;
	};

	/**
	 * Upload the file to s3 on client-side
	 * @param {Object} imageFile the file to upload with html file-input format
	 */
	const uploadImageToS3 = async imageFile => {
		let fileName = imageFile.name;
		let contentType = imageFile.type;
		let size = imageFile.size;

		try {
			const compositeData = await getUploadS3SignedUrlImage(
				fileName,
				contentType,
				size,
				fileObject,
				channelCode
			);

			await uploadS3FromSignedUrl(compositeData.signedUrl, imageFile);
			return compositeData;
		} catch (error) {
			//console.error(error);
		}
	};

	/**
	 * On paste we want to replace url with a anchor to create a clickable link
	 * @param {object} event javascript ClipboardEvent
	 */
	const onPaste = event => {
		const clipboardData = event.clipboardData || window.clipboardData;
		const pastedData = clipboardData.getData("Text");
		const linkExpression = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/gm;
		const emailExpression =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (pastedData.match(linkExpression)) {
			suneditor.current.insertHTML(`<a href="${pastedData}">${pastedData}</a>`);
			return false;
		} else if (pastedData.match(emailExpression)) {
			suneditor.current.insertHTML(`<a href="mailto:${pastedData}">${pastedData}</a>`);
			return false;
		}

		return true;
	};

	/**
	 *
	 * @param {*} e
	 */
	const readFileHandler = e => {
		const files = e.target?.files || e;

		Array.from(files).forEach(file => {
			let fileSize = -1;
			if (file) {
				fileSize = file.size;
			}
			if (fileSize > 0) {
				dispatch(fetchUploadImageToS3(assistantID, file, fileObject, null, addAttachment));
			}
		});
		fileInputRef.current.value = "";
	};

	return (
		<>
			<SunEditor
				placeholder={placeholder}
				getSunEditorInstance={getSunEditorInstance}
				showToolbar={true}
				onChange={handleChange}
				setDefaultStyle="height: 150px; font-size: 14px"
				onImageUploadBefore={(files, info, uploadHandler) => {
					uploadImageToS3(files[0]).then(compositeData => {
						const { name, size } = files[0];
						uploadHandler({
							result: [
								{
									url: compositeData?.fileObject?.url,
									name,
									size
								}
							]
						});
					});
				}}
				onPaste={onPaste}
				setContents={value}
				setOptions={options}
			/>
			<input
				type="file"
				style={{ display: "none" }}
				onChange={readFileHandler}
				ref={fileInputRef}
			/>
		</>
	);
};
